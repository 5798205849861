import React from "react";
import PropTypes from "prop-types";

import { FaHome } from "react-icons/fa/";
import { FaSearch } from "react-icons/fa/";
import { FaEnvelope } from "react-icons/fa/";
import { FaMediumM } from "react-icons/fa/";
import { FaGithub } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";

import Item from "./Item";
import Item2 from "./Item2";
import Expand from "./Expand";

require("core-js/fn/array/from");

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.itemList = React.createRef();
    this.itemList2 = React.createRef();
    this.itemList3 = React.createRef();
    this.itemList4 = React.createRef();

    const pages = props.pages.map(page => ({
      to: page.node.fields.slug,
      label: page.node.frontmatter.menuTitle
        ? page.node.frontmatter.menuTitle
        : page.node.frontmatter.title
    }));

    this.items = [
      { to: "/", label: "Home", icon: FaHome },
    ];

    this.items2 = [
      { to: "https://www.github.com/saaras-io", label: "github", icon: FaGithub},
    ];
    this.items3 = [
      { to: "https://saarasio.medium.com/", label: "Blog", icon: FaMediumM},
    ];

	this.items4 = [
      { to: "/contact2/", label: "Contact", icon: FaEnvelope }
	];

    this.renderedItems = []; // will contain references to rendered DOM elements of menu
  }

  state = {
    open: false,
    hiddenItems: []
  };

  static propTypes = {
    path: PropTypes.string.isRequired,
    fixed: PropTypes.bool.isRequired,
    screenWidth: PropTypes.number.isRequired,
    fontLoaded: PropTypes.bool.isRequired,
    pages: PropTypes.array.isRequired,
    theme: PropTypes.object.isRequired
  };

  render() {
    const { screenWidth, theme } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
          <ul className="itemList" ref={this.itemList}>
            {this.items.map(item => (
              <Item item={item} key={item.label} icon={item.icon} theme={theme} />
            ))}
          </ul>
        <ul className="itemList3" ref={this.itemList3}>
            {this.items3.map(item => (
              <Item2 item={item} key={item.label} icon={item.icon} theme={theme} />
            ))}
          </ul>
        <ul className="itemList2" ref={this.itemList2}>
            {this.items2.map(item => (
              <Item2 item={item} key={item.label} icon={item.icon} theme={theme} />
            ))}
          </ul>
        <ul className="itemList4" ref={this.itemList4}>
            {this.items4.map(item => (
              <Item item={item} key={item.label} icon={item.icon} theme={theme} />
            ))}
          </ul>
 
          
        {/* --- STYLES --- */}
        <style jsx>{`
          .menu {
            align-items: center;
            background: ${theme.color.neutral.white};
            bottom: 0;
            display: flex;
            flex-grow: 1;
            left: 0;
            max-height: ${open ? "1000px" : "50px"};
            padding: 0 ${theme.space.inset.s};
            position: fixed;
            width: 100%;
            z-index: 1;
            transition: all ${theme.time.duration.default};
          }

          .itemList {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0; /* 0 ${theme.space.s}; */
            position: relative;
            width: 100%;
          }

          @below desktop {
            .menu {
              &::after {
                position: absolute;
                content: "";
                left: ${theme.space.m};
                right: ${theme.space.m};
                top: 0;
                height: 1px;
                background: ${theme.color.brand.primary};
              }

              &.open {
                padding: ${theme.space.inset.m};
              }

              :global(.homepage):not(.fixed) & {
                bottom: -100px;
              }
            }
          }

          @from-width desktop {
            .menu {
              border-top: none;
              background: transparent;
              display: flex;
              position: relative;
              justify-content: flex-end;
              padding-left: 50px;
              transition: none;
            }

            .itemList {
              justify-content: flex-end;
              padding: 0;
            }

            .hiddenItemList {
              list-style: none;
              margin: 0;
              position: absolute;
              background: ${theme.background.color.primary};
              border: 1px solid ${theme.line.color};
              top: 48px;
              right: ${theme.space.s};
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              padding: ${theme.space.m};
              border-radius: ${theme.size.radius.small};
              border-top-right-radius: 0;


              &:after {
                content: "";
                background: ${theme.background.color.primary};
                z-index: 10;
                top: -10px;
                right: -1px;
                width: 44px;
                height: 10px;
                position: absolute;
                border-left: 1px solid ${theme.line.color};
                border-right: 1px solid ${theme.line.color};
              }

              :global(.homepage):not(.fixed) & {
                border: 1px solid transparent;
                background: color(white alpha(-10%));
                top: 50px;

                &:after {
                  top: -11px;
                  border-left: 1px solid transparent;
                  border-right: 1px solid transparent;
                  background: color(white alpha(-10%));
                }
              }

              :global(.fixed) & {
                top: 44px;
              }
            }
          }
        `}</style>
      </React.Fragment>




    );
  }
}

export default Menu;
